import {BaseRequest} from "@/request/BaseRequest";

class WithdrawApply extends BaseRequest {

    public requestPath: string = '/after_sale/withdrawApply';

    /**
     * 审核
     * @param data
     */
    deal(data: {
        id: string,
        applyStatus: number,
        auditDesc: string
    }): any {
        return this.post(`${this.requestPath}/deal`, data);
    }

}

const c = new WithdrawApply();
export {c as WithdrawApply}