
























import {Vue, Component} from "vue-property-decorator";
import {WithdrawApply} from "@/request/after-sale/WithdrawApply";

@Component({
    name: 'after_sale_withdrawApply'
})
export default class after_sale_withdrawApply extends Vue {
    modal: any = WithdrawApply;
    search: Array<any> = [
        {
            type: 'Input',
            label: '电话',
            key: 'phone',
            width: '140px',
            clearable: true,
            placeholder: '请填写电话'
        },
        {
            type: 'Select',
            label: '分销状态',
            key: 'applyStatus',
            clearable: true,
            width: '120px',
            items: [
                {
                    label: '待审核',
                    value: 0
                },
                {
                    label: '通过',
                    value: 1
                },
                {
                    label: '拒绝',
                    value: 2
                }
            ]
        },
        {
            type: 'DatePicker',
            pickerType: 'daterange',
            format: 'yyyy-MM-dd',
            label: '提现时间',
            width: '210px',
            placeholder: '请选择注册时间'
        }
    ];
    dealModal: any = {
        show: false,
        id: '',
        applyStatus: 1,
        auditDesc: ''
    };
    columns: Array<any> = [
        {
            title: '头像',
            key: 'avatarUrl',
            minWidth: 80,
            render: (h: any, params: any) => {
                return h('img', {
                    style: {
                        borderRadius: '3px',
                        height: '40px',
                        width: '40px',
                        marginTop: '5px'
                    },
                    attrs: {
                        src: params.row.avatarUrl
                    }
                })
            }
        },
        {
            title: '昵称',
            key: 'nickName',
            minWidth: 150
        },
        {
            title: '电话',
            key: 'phone',
            minWidth: 140
        },
        {
            title: '当前金额',
            key: 'applyCurFee',
            minWidth: 120,
            render: (h: any, p: any) => h('p', this.fen2yuan(p.row.applyCurFee))
        },
        {
            title: '申请提现金额',
            key: 'applyFee',
            minWidth: 120,
            render: (h: any, p: any) => h('p', this.fen2yuan(p.row.applyFee))
        },
        {
            title: '审核状态',
            key: 'applyStatus',
            minWidth: 120,
            render: (h: any, p: any) => h('p', p.row.applyStatus===0?'待审核':p.row.applyStatus===1?'通过':p.row.applyStatus===2?'拒绝':'-')
        },
        {
            title: '审核说明',
            key: 'auditDesc',
            minWidth: 120
        },
        {
            title: '申请时间',
            key: 'createTime',
            minWidth: 180
        }
    ];
    deal(row: any) {
        this.dealModal.id = row.id;
        this.dealModal.applyStatus = 1;
        this.dealModal.show = true;
    }
    submit() {
        let self: any = this;
        this.$Message.loading('请稍后');
        WithdrawApply.deal({
            id: this.dealModal.id,
            applyStatus: this.dealModal.applyStatus,
            auditDesc: this.dealModal.auditDesc
        }).then((body: any) => {
            if (body.code === 0) {
                this.$Message.info('审核成功');
                self.$refs['el-table'].flush();
            }
        })
    }
}
